import { ChannelShowMessenger } from "component/app/channelTalk";
import { BtnBox, BtnItem } from "component/basic/btns";
import { useLocation, useNavigate } from "react-router-dom";

//footer
function FooterLink(data){

    return (
        <>
        {data.btnType == "link" ? 
        <a href={data.btnLink} target="_blank" className={`footerLink ${data.addClass ? data.addClass : ""}`} disabled={data.disabled ? data.disabled : false} dangerouslySetInnerHTML={{__html:data.contents}}></a>
        :
        <button type="button" className={`footerLink ${data.addClass ? data.addClass : ""}`} onClick={() => data.func()} disabled={data.disabled ? data.disabled : false} dangerouslySetInnerHTML={{__html:data.contents}}></button>
        }
        </>
    );
}

function FooterInfoBox(data){

    return (
        <div className={`footerInfoBox ${data.addClass ? data.addClass : ""}`}>
            {data.children}
        </div>
    );
}

function FooterInfoItem(data){

    return (
        <div className={`footerInfoItem ${data.addClass ? data.addClass : ""}`}>
            <p className="footerInfoItem_name" dangerouslySetInnerHTML={{__html:data.name}}/>
            <p className="footerInfoItem_text" dangerouslySetInnerHTML={{__html:data.text}}/>
        </div>
    );
}

function Footer(data){
    const navigate = useNavigate();
    const { pathname } = useLocation();

    return (
        <>
        {pathname == "/" || pathname == "/main" || pathname == "/func" || pathname == "/price" ?
            <div className="footer">
                <div className="footer_section">
                    <div className="footer_header">
                        <img src="/assets/images/footer_logo.svg"/>
                        <div className="footer_linkBox">
                            <FooterLink
                                btnType={null}
                                btnLink={null}
                                contents="Home"
                                func={() => {
                                    navigate("/")
                                }}
                            />
                            <FooterLink
                                btnType={null}
                                btnLink={null}
                                contents="가격정책"
                                func={() => {
                                    navigate("/price")
                                }}
                            />
                            <FooterLink
                                btnType={null}
                                btnLink={null}
                                contents="도입문의"
                                func={() => {
                                    //채널톡 활성화
                                    ChannelShowMessenger();
                                }}
                            />
                            <FooterLink
                                btnType="link"
                                btnLink="https://www.notion.so/wavelabs/WAVE-ONE-5aeb7a165af7427aabf6855378b91fca?pvs=4"
                                contents="고객지원"
                                func={() => {
                                }}
                            />
                            <FooterLink
                                btnType="link"
                                btnLink="https://www.notion.so/wavelabs/WAVE-ONE-Blog-3782baa13db4416b97a8ded89ca4b920?pvs=4"
                                contents="블로그"
                                func={() => {
                                }}
                            />
                        </div>
                    </div>
                    <FooterInfoBox>
                        <FooterInfoItem
                            name="주식회사"
                            text="웨이브코드"
                        />
                        <FooterInfoItem
                            name="대표자"
                            text="박상민"
                        />
                    </FooterInfoBox>
                    <FooterInfoBox>
                        <FooterInfoItem
                            name="사업자등록번호"
                            text="808-87-03227"
                        />
                        <FooterInfoItem
                            name="통신판매업신고번호"
                            text="준비중"
                        />
                    </FooterInfoBox>
                    <FooterInfoBox>
                        <FooterInfoItem
                            name="대표번호"
                            text="02-6925-6160"
                        />
                        <FooterInfoItem
                            name="이메일"
                            text="welcome@wavecode.io"
                        />
                    </FooterInfoBox>
                    <FooterInfoBox>
                        <FooterInfoItem
                            name="주소"
                            text="서울특별시 강남구 강남대로 484, 4층 414호"
                        />
                    </FooterInfoBox>
                </div>
                <div className="footer_header">
                    <p className="footer_caption">Copyright ⓒ 2023 WAVEONE All rights reserved</p>
                    <div className="footer_linkBox">
                        <FooterLink
                            btnType="link"
                            btnLink="https://www.notion.so/waveglobals/WAVEONE-2023-11-01-397c55f5373a46eeb95b73df9bf0a77d?pvs=4"
                            contents="서비스 이용약관"
                            func={() => {
                            }}
                        />
                        <FooterLink
                            btnType="link"
                            btnLink="https://www.notion.so/waveglobals/WAVEONE-2023-11-01-f9d5a301be4445fb80f64cd61e22ab79?pvs=4"
                            contents="개인정보 처리방침"
                            func={() => {
                            }}
                        />
                    </div>
                </div>
                <BtnBox
                    boxType="fixed"
                    addClass="mItem"
                >
                    <BtnItem
                        addClass=""
                        btnType="link"
                        btnLink="https://admin.waveone.team/request"
                        contents={"무료체험 신청"}
                        disabled={false}
                        func={() => {
                        }}
                    />
                </BtnBox>
            </div>
            : ""}
        </>
    );
}

export {Footer}